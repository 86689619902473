import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ProductUpsellCardWithDropdown from "../upsell-cards/product-upsell-card-with-dropdown";

import { useStaticQuery, graphql } from "gatsby";

const ProductsUpsellSection = ({ allStripeProducts, images, ...props }) => {
  const compatibilityData = useStaticQuery(graphql`
    {
      upsellList: allUpsellProductsJson(
        filter: { name: { ne: "Extra Universal Holder" } }
      ) {
        nodes {
          description
          type
          imageFolder
          name
          stripeName
        }
      }
    }
  `);

  const upsellList = compatibilityData.upsellList.nodes;

  function findStripeUpsellProduct(UpsellType) {
    const stripeProduct = allStripeProducts.filter(
      (product) => product.type === UpsellType
    );
    return stripeProduct;
  }

  function findCarrouselUpsellImages(imgFolder) {
    const carrousselImages = images.allFile.edges.filter((item) =>
      item.node.relativeDirectory.includes(imgFolder)
    );

    return carrousselImages;
  }

  return (
    <Box sx={{ padding: 1, marginTop: 1 }}>
      <Container maxWidth={"lg"}>
        <Grid container>
          <Grid container justifyContent={"space-evenly"} spacing={1}>
            {upsellList.map((item) => {
              return (
                <ProductUpsellCardWithDropdown
                  name={item.name}
                  products={findStripeUpsellProduct(item.type)}
                  description={item.description}
                  images={findCarrouselUpsellImages(item.imageFolder)}
                  data={findStripeUpsellProduct(item.type)}
                />
              );
              // );
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProductsUpsellSection;
