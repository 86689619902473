import React, { useContext } from "react";
import Container from "@mui/material/Container";
import { Typography, Checkbox, Breadcrumbs, Stack } from "@mui/material";
import CartModal from "../../../../../components/ecommerce/store/cartModal";
import SEO from "../../../../../components/layout/seo";
import { myContext } from "../../../../../utils/provider";

import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

import { CartProvider } from "use-shopping-cart";

import Layout from "../../../../../components/layout/layout-ecommerce";
import ProductsUpsellSection from "../../../../../components/ecommerce/sections_upsell/products-upsell-section";
import useStripeProducts from "../../../../../components/ecommerce/products/useStripeProducts";

import { useStaticQuery, graphql, navigate, Link } from "gatsby";
import Compatibility from "../../../../../components/ecommerce/compatibility/modal-compatibility";
import MicrorecLowerpart from "../../../../../components/ecommerce/sections_microREC/microrec_lowerpart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CircleIcon from "@mui/icons-material/Circle";

const isBrowser = typeof window !== "undefined";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#3D3838",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const UpsellPage = ({ location }) => {
  const allImages4upsell = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { regex: "/upsell-images/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            name
            relativeDirectory
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 80
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);
  const context = useContext(myContext);
  const getCurrency = () => {
    if (context.ecommerceDollar) {
      return "USD";
    } else {
      return "EUR";
    }
  };
  try {
    const products = useStripeProducts();

    const linkToSoftware = () => {
      navigate("/store/microrec/software/adapters/accessories", {});
    };

    const currentPath = location.pathname;

    const breadcrumbs = [
      <Link underline="hover" key="1" color="inherit" href="/store/microrec">
        <CircleIcon
          fontSize="small"
          sx={{
            color: currentPath === "/store/microrec" ? "#FFA100" : "#CACACA",
          }}
        />
      </Link>,
      <Link
        underline="hover"
        key="2"
        color="inherit"
        href="/store/microrec/software"
      >
        <CircleIcon
          fontSize="small"
          sx={{
            color:
              currentPath === "/store/microrec/software"
                ? "#FFA100"
                : "#CACACA",
          }}
        />
      </Link>,
      <Link
        underline="hover"
        key="3"
        color="inherit"
        href="/store/microrec/software/adapters"
      >
        <CircleIcon
          fontSize="small"
          sx={{
            color:
              currentPath === "/store/microrec/software/adapters" ||
              currentPath === "/store/microrec/software/adapters/"
                ? "#FFA100"
                : "#CACACA",
          }}
        />
      </Link>,
      <Link
        underline="hover"
        key="4"
        color="inherit"
        href="/store/microrec/software/adapters/accessories"
      >
        <CircleIcon
          fontSize="small"
          sx={{
            color:
              currentPath === "/store/microrec/software/adapters/accessories"
                ? "#FFA100"
                : "#CACACA",
          }}
        />
      </Link>,
    ];

    return (
      <ThemeProvider theme={cs_theme}>
        <CartProvider
          stripe={process.env.STRIPE_PUBLISHABLE_KEY}
          currency={getCurrency()}
        >
          <Layout helmetName="Adapters">
            <Container id="accesories_page" disableGutters maxWidth={"lg"}>
              <SEO
                title="Custom Surgical Store | Adapters"
                keywords={[`gatsby`, `application`, `react`]}
              />

              <CartModal />
              <Stack spacing={2} style={{ marginTop: "120px" }}>
                <Breadcrumbs
                  separator={
                    <Typography variant="h6">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                  }
                  aria-label="breadcrumb"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    gap: 2,
                  }}
                >
                  {breadcrumbs}
                </Breadcrumbs>{" "}
              </Stack>
              <Compatibility></Compatibility>
              <ProductsUpsellSection
                allStripeProducts={products}
                images={allImages4upsell}
              />
              <div style={{ textAlign: "center" }}>
                {" "}
                <button
                  onClick={linkToSoftware}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    marginTop: "0em",
                  }}
                >
                  {" "}
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                  />
                  I already have a Beam Splitter and I do not use Leica or
                  Haag-Streeit.
                </button>
              </div>
              <MicrorecLowerpart />
            </Container>
          </Layout>
        </CartProvider>
      </ThemeProvider>
    );
  } catch {
    if (isBrowser) {
      navigate("/");
    }
    return (
      <CartProvider
        stripe={process.env.STRIPE_PUBLISHABLE_KEY}
        currency={getCurrency()}
      >
        <Container id="accesories_page" disableGutters maxWidth={false}>
          <Layout></Layout>
        </Container>
      </CartProvider>
    );
  }
};
export default UpsellPage;
